








































































































































import { Component, Vue } from "vue-property-decorator";
import ModalShowProfile from "@/components/Modal/ModalShowProfile.vue";
import { Member, EMemberRole, IUserGroupRole, UserProfile } from "@/models";
import GroupMemberService from "@/services/GroupMemberService";
import PreviewText from "@/helpers/PreviewText";
import UserService from "@/services/UserService";
import GroupService from "@/services/GroupService";

/**
 * f2-301
 * サークルメンバー一覧画面
 */
@Component({ components: { ModalShowProfile } })
export default class MemberList extends Vue {
  private memberList: Member[] = [];
  private memberCount = 0;
  private eRole = EMemberRole;
  private page: number = 1;
  private maxPage: number = 1;
  private noAvatar = require("@/assets/images/avatar-default.png");
  private selectedUserId: number = -1;
  private limit: number = this.$store.state.setting.setting.setting_display
    .number_item_lists;
  private modalMess: string = "";
  private modalAction = () => {};
  private kickMemberId: number = -1;
  private isDefault: any = "";

  async created() {
    // await this.checkGroupDefault()
    await this.getAllMember();
  }

  checkGroupDefault() {
    GroupService.checkGroupDefault(this.$route.params.groupId).then((res) => {
      if (res.status == 200) {
        this.isDefault = res.data;
        if (this.isDefault === 1) {
          this.modalMess = this.$t("common.message.uncatch_error") as string;
          this.$bvModal.show("modal-error");
        } else {
          this.getAllMember();
        }
      }
    });
  }

  /**
   * Call API get all member of group
   */
  getAllMember(loadMore?: boolean) {
    GroupMemberService.getAllMembersByGroupId(
      this.$route.params.groupId,
      this.page,
      this.limit
    )
      .then((res) => {
        if (res.status === 200) {
          const data = res.data.data.map((item: any) => new Member(item));
          if (loadMore) {
            this.memberList = [...this.memberList, ...data];
          } else {
            this.memberList = data;
          }
          this.maxPage = res.data.last_page;
          this.memberCount = res.data.total;
        }
      })
      .catch((err) => {
        this.modalMess = this.$t("common.message.uncatch_error") as string;
        this.$bvModal.show("modal-error");
      });
  }

  /**
   * Call API for loadmore
   */
  loadMore() {
    ++this.page;
    this.getAllMember(true);
  }

  async showMemberProfile(profile?: UserProfile) {
    this.selectedUserId = profile ? profile.user_id : -1;
    // const sameGroupTag = await this.getUserProfileById()
    // if (sameGroupTag) {
    this.$bvModal.show("modal-show-profile");
    // }
  }

  /**
   * Check user is same group tag
   */
  // async getUserProfileById() {
  //   return UserService.getUserProfileById(this.selectedUserId)
  //     .then(res => {
  //       return res.status === 200 && res.data.is_same_group_tag
  //     })
  //     .catch(() => {
  //       return false
  //     })
  // }

  confirmDeleteMember(memberId: number) {
    this.kickMemberId = memberId;
    this.modalMess = this.$t("common.message.delete") as string;
    this.modalAction = () => this.handleRemoveMember();
    this.$bvModal.show("modal-confirm-member-list");
  }

  async handleRemoveMember() {
    this.$blockui.show();
    GroupMemberService.removeMember(
      this.$route.params.groupId,
      this.kickMemberId
    )
      .then((res) => {
        if (res.status === 200) {
          this.memberList = this.memberList.filter(
            (item) => item.id !== this.kickMemberId
          );

          this.modalMess = this.$t("common.message.deleted") as string;
          this.modalAction = () => {};
          this.$bvModal.show("modal-success");
        }
      })
      .catch((err) => {
        if (err.response.status === 404) {
          this.memberList = this.memberList.filter(
            (item) => item.id !== this.kickMemberId
          );

          this.modalMess = this.$t(
            "common.message.member_not_in_group"
          ) as string;
          this.$bvModal.show("modal-error");
        } else {
          this.modalMess = this.$t("common.message.delete_fail") as string;
          this.$bvModal.show("modal-error");
        }
      })
      .finally(() => {
        this.$blockui.hide();
      });
  }

  leaveGroupModalConfirm() {
    this.$bvModal.show("modal-confirm-member-list");
    this.modalMess = this.$t("common.message.leave_group") as string;
    this.modalAction = () => this.handleLeaveGroup();
  }

  async handleLeaveGroup() {
    if (this.memberCount === 1) {
      this.modalMess = String(this.$t("common.message.cant_leave_one_mem"));
      this.$bvModal.show("modal-info");
      return;
    }
    const groupRole: IUserGroupRole = this.$store.state.userInfo.user
      .group_role;
    if (
      String(groupRole.group_id) === this.$route.params.groupId &&
      !groupRole.role_admin
    ) {
      this.leaveGroup();
    } else {
      const checkAdminOther = await this.checkAdminOther();
      if (checkAdminOther) {
        this.leaveGroup();
      }
    }
  }

  async checkAdminOther() {
    const groupId = this.$route.params.groupId;
    return GroupMemberService.findAdminWithoutMe(groupId)
      .then((res) => {
        return res.status === 200;
      })
      .catch((err) => {
        const groupRole: IUserGroupRole = this.$store.state.userInfo.user
          .group_role;
        if (
          err.response.status === 404 &&
          String(groupRole.group_id) === groupId &&
          groupRole.role_admin
        ) {
          this.modalMess = String(this.$t("common.message.set_admin"));
          this.$bvModal.show("modal-info");
        }
      });
  }

  leaveGroup() {
    this.$blockui.show();
    GroupMemberService.leaveCurrentGroup(this.$route.params.groupId)
      .then((res) => {
        if (res.status === 200) {
          this.modalMess = String(this.$t("common.message.deleted"));
          this.modalAction = () => this.jupmToHome();
          this.$bvModal.show("modal-success");
        }
      })
      .catch(() => {
        this.modalMess = String(this.$t("common.message.delete_fail"));
        this.$bvModal.show("modal-error");
      })
      .finally(() => this.$blockui.hide());
  }

  getFullName(profile?: UserProfile) {
    const name = [
      profile?.first_name,
      profile?.last_name,
      String(this.$t("groups.member_list.name_suffix")),
    ].join(" ");
    const preview = PreviewText.covertToPreviewText(name, 15);
    return {
      name: name,
      text: preview.text,
      isLong: preview.isLong,
    };
  }

  jupmToHome() {
    this.$router.push({ name: "home" });
  }
}
