var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"flex-1 mb-10"},[_c('Title',{staticClass:"mt-1 mt-xl-5"},[_c('div',{staticClass:"d-flex justify-content-between align-items-cente"},[_vm._v(" "+_vm._s(_vm.$t("groups.member_list.member.joined_member"))+" "),_c('span',{staticClass:"count"},[_vm._v(" "+_vm._s(_vm.$t("groups.member_list.memberCount", { count: _vm.memberCount, }))+" ")])])]),_c('div',{staticClass:"content bg-body-primary px-xl-3 pt-xl-3 border-bottom-left-right-radius"},[_c('div',{staticClass:"d-xl-flex justify-content-between flex-wrap"},[_vm._l((_vm.memberList),function(member){return _c('div',{key:member.id,staticClass:" w-xl-30"},[_c('div',{staticClass:"d-flex align-items-center justify-content-between px-3"},[_c('div',{staticClass:"d-flex align-items-center"},[_c('img',{staticClass:"rounded-pill h--9 w--9 my-2 img-cover flex-fixed",attrs:{"src":member.user_profile && member.user_profile.avatar_small
                  ? member.user_profile.avatar_small
                  : _vm.noAvatar}}),(member.status_company == 'stop')?_c('div',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.left",value:('停止された企業様'),expression:"'停止された企業様'",modifiers:{"hover":true,"left":true}}],staticClass:"ps-3 text-primary cursor-pointer text-line-clamp-1"},[_vm._v(" 停止された企業様 ")]):(member.status_company == 'leave')?_c('div',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.left",value:('退会された企業様'),expression:"'退会された企業様'",modifiers:{"hover":true,"left":true}}],staticClass:"ps-3 text-primary cursor-pointer text-line-clamp-1"},[_vm._v(" 退会された企業様 ")]):(
                (member.is_company == 1 && !member.status_company) ||
                  (member.is_company == 1 &&
                    member.status_company == 'register')
              )?_c('div',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.left",value:(member.name + '@' + member.name_company),expression:"member.name + '@' + member.name_company",modifiers:{"hover":true,"left":true}}],staticClass:"ps-3 text-primary cursor-pointer text-line-clamp-1",on:{"click":function($event){return _vm.showMemberProfile(member.user_profile)}}},[_vm._v(" "+_vm._s(member.name + "@" + member.name_company)+" "+_vm._s(_vm.$t("groups.member_list.name_suffix"))+" ")]):_c('div',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.left",value:(member.name),expression:"member.name",modifiers:{"hover":true,"left":true}}],staticClass:"ps-3 text-primary cursor-pointer text-line-clamp-1",on:{"click":function($event){return _vm.showMemberProfile(member.user_profile)}}},[_vm._v(" "+_vm._s(member.name + " " + _vm.$t("groups.member_list.name_suffix"))+" ")])]),_c('div',{staticClass:"d-flex"},[(
                _vm.$store.state.userInfo.user.group_role.role_admin &&
                  member.id !== _vm.$store.state.userInfo.user.info.id
              )?_c('span',{staticClass:"text-primary cursor-pointer hover-o-75 text-nowrap",on:{"click":function($event){return _vm.confirmDeleteMember(member.id)}}},[_vm._v(" "+_vm._s(_vm.$t("groups.delete"))+" ")]):_vm._e(),(member.isAdmin)?_c('span',{staticClass:"text-nowrap w--18 w-xl--22 d-flex justify-content-end"},[_vm._v(" "+_vm._s(_vm.$t("groups.member_list.role_admin"))+" ")]):(member.user_permissions.length !== 0)?_c('span',{staticClass:"text-nowrap w--18 w-xl--22 d-flex justify-content-end"},[_vm._v(" "+_vm._s(_vm.$t("groups.member_list.role_member_plus"))+" ")]):_c('span',{staticClass:"text-nowrap w--18 w-xl--22 d-flex justify-content-end"},[_vm._v(" "+_vm._s(_vm.$t("groups.member_list.role_member"))+" ")])])]),_c('div',{staticClass:"border-bottom-xl border-secondary mx-3"})])}),(_vm.memberList.length % 3 === 2)?_c('div',{staticClass:" w-xl-30"}):_vm._e()],2),_c('div',{staticClass:"flex-center position-relative py-5 py-xl-8 justify-content-xl-start px-xl-2"},[(_vm.page < _vm.maxPage)?_c('button',{staticClass:"btn btn-outline-secondary-deep text-nowrap button-border-radius",on:{"click":_vm.loadMore}},[_vm._v(" "+_vm._s(_vm.$t("common.btn.btn_see_more"))+" ")]):_vm._e(),_c('div',{staticClass:"d-flex align-items-center position-absolute start-0 end-xl-0 text-primary cursor-pointer mx-3",on:{"click":_vm.leaveGroupModalConfirm}},[_vm._v(" "+_vm._s(_vm.$t("groups.member_list.member.leave_group"))+" ")])])]),_c('ModalShowProfile',{attrs:{"userId":_vm.selectedUserId}}),_c('ModalConfirm',{attrs:{"id":'modal-confirm-member-list',"confirmMess":_vm.modalMess},on:{"yes":_vm.modalAction}}),_c('ModalInfo',{attrs:{"infoMess":_vm.modalMess}}),_c('ModalError',{attrs:{"errorMess":_vm.modalMess}}),_c('ModalSuccess',{attrs:{"successMess":_vm.modalMess},on:{"close":_vm.modalAction}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }